.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

body {
    width: 100vw;
    height: 100vh;
    background: #000 !important;
}