.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 80%;
    max-width: 500px;
}

label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

input,
textarea {
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 500px;
}

button[type="submit"] {
    background-color: #00bfff;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px 20px;
    transition: background-color 0.2s ease-in-out;
}

button[type="submit"]:hover {
    background-color: #00a5d7;
}