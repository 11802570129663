body {
    width: 100vw;
    height: 100vh;
    background: #000;
}

section {
    width: 500px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.button {
    margin-top: 1rem;
    position: relative;
    display: inline-block;
    background-color: #fff;
    border: none;
    color: #000;
    padding: 12px 24px;
    border-radius: 30px;
    font-size: 1rem;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.button:hover {
    transform: translateY(-1px);
    background-color: aliceblue;
}

.button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
    z-index: -1;
}

.button:active::before {
    width: 200%;
    height: 200%;
}

.face {
    width: 400px;
    height: 500px;
    perspective: 700px;
}

.front,
.back {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 10px;
    transition: 700ms;
}

.front2,
.back2 {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 10px;
    transition: 700ms;
}

.front {
    background: limegreen;
    z-index: 1;
}

.front2 {
    background: limegreen;
    z-index: 0;
    backface-visibility: hidden;
}

.back {
    background: lightslategray;
    transform: rotateY(-180deg);
    backface-visibility: hidden;
}

.back2 {
    background: lightslategray;
    transform: rotateY(0deg);
    backface-visibility: hidden;
}

.face .front {
    transform: rotateY(180deg);
    z-index: 0;
}

.face .back {
    transform: rotateY(0);
    z-index: 1;
}

.front2 {
    transform: rotateY(0);
    z-index: 0;
}

.back2 {
    transform: rotateY(-180deg);
    z-index: 1;
}



.modal {
    width: 80vw;
    height: 60vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid gray;
    outline: none;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}